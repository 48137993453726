import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

import Document from './document';
import Sent from './sent'
import Inbox from './inbox'
import Approval from './approval';
import Acknowledge from './acknowledge/';
import Preview from './preview';

import Histori from './history/Histori';
import HistoriDetail from './history/HistoriDetail';
import ViewFileHistory from './history/ViewFileHistory';

import NotFound from './document/NotFound';
import Search from './search/Search';
import { Annoucement } from './announcement/Announcement';
import AnnouncementCreate from './announcement/AnnouncementCreate';
import AnnouncementEdit from './announcement/AnnouncementEdit';
import AnnouncementView from './announcement/AnnouncementView';
import AnnoucementDraft from './announcement/AnnoucementDraft';

import UrlShort from './linktree/UrlShort';
import Linktree from './linktree/Linktree';
import LinktreeDetail from './linktree/LinktreeDetail';

import Notification from './notification/Notification';
import KaryawanAdd from './karyawan/Karyawan-add';
import Karyawan from './karyawan/Karyawan';
import KaryawanAddManual from './karyawan/Karyawan-add-manual';
import Group from './group/Group';
import GroupDetail from './group/GroupDetail';
import LevelOrganization from './levelorganization/LevelOrganization';
import ListofApprovers from './listofapprovers/ListofApprovers';
import InvestmentManager from './investmentmanager/InvestmentManager';
import AntiMoneyLaundry from './aml/AntiMoneyLaundry';

import { MenuManagement } from './management/menumanagement';
import { RoleManagement } from './management/rolemanagement';
import { AuthorityManagement } from './management/authoritymanagement';
import { RoleMenu } from './management/rolemenu';
import NewNotFound from './newnotfound/newnotfound';
import NewUnAuthorize from './newunauthorize/newunauthorize';

import SummaryAnalyst from './researchreport/summaryanalyst';
import RatingAndTarget from './researchreport/ratingandtarget';
import MakeNewRequest from './researchreport/makenewrequest';
import ProfileAnalyst from './researchreport/profileanalyst';
import InvestmentManagerDetail from './investmentmanager/InvestmentManagerDetail';
import HistoryGenerateTC from './investmentmanager/HistoryGenerateTC';
import HistoryGenerateTCResult from './investmentmanager/HistoryGenerateTCResult';
import CreateNewTC from './investmentmanager/CreateNewTC';
import CreateNewTCPreview from './investmentmanager/CreateNewTCPreview';
import CreateNewTCResult from './investmentmanager/CreateNewTCResult';
import UserManual from './document/user-manual';
import CandidateList from './career/CandidateList';
import CandidateDetail from './career/CandidateDetail';
import ListJobs from './career/JobsList';
import JobsDetail from './career/JobsDetail';
import JobsAdd from './career/JobsAdd';
import JobsEdit from './career/JobsEdit';
import WhistleblowingList from './whistleblowing/ReportList';
import WhistleblowingDetail from './whistleblowing/ReportDetail';

// import {TrendAnalyst, SummaryAnalyst, ListReportRequested} from './researchreport';

/* jhipster-needle-add-route-import - JHipster will add routes here */

// import ForgotPassword from 'app/modules/forgotpassword/forgotpassword';

const Routes = ({ match }) => (
  <div>
    <Switch>
      
      {/* <ErrorBoundaryRoute path={`${match.url}forgot/password`} component={ForgotPassword} /> */}

      {/* prettier-ignore */}
      
      <ErrorBoundaryRoute path={`${match.url}draft`} component={Document} />
      <ErrorBoundaryRoute path={`${match.url}sent`} component={Sent} />
      <ErrorBoundaryRoute path={`${match.url}inbox`} component={Inbox} />
      <ErrorBoundaryRoute path={`${match.url}approval`} component={Approval} />
      <ErrorBoundaryRoute path={`${match.url}acknowledge`} component={Acknowledge} />
      <ErrorBoundaryRoute path={`${match.url}preview`} component={Preview} />

      <ErrorBoundaryRoute path={`${match.url}history`} exact component={Histori} />
      <ErrorBoundaryRoute path={`${match.url}history/detail/:id`} component={HistoriDetail} />
      <ErrorBoundaryRoute path={`${match.url}history/viewfile`} component={ViewFileHistory} />


      <ErrorBoundaryRoute path={`${match.url}search`} component={Search} />
      <ErrorBoundaryRoute path={`${match.url}announcement/list`} component={Annoucement} />
      <ErrorBoundaryRoute path={`${match.url}announcement/draft`} component={AnnoucementDraft} />
      <ErrorBoundaryRoute path={`${match.url}announcement/create`} component={AnnouncementCreate} />
      <ErrorBoundaryRoute path={`${match.url}announcement/edit/:id`} component={AnnouncementEdit} />
      <ErrorBoundaryRoute path={`${match.url}announcement/view/:id`} component={AnnouncementView} />

      <PrivateRoute path={`${match.url}url-short`} exact component={UrlShort} hasAnyAuthorities={[AUTHORITIES.MARKETING]} />
      <PrivateRoute path={`${match.url}linktree`} exact component={Linktree} hasAnyAuthorities={[AUTHORITIES.MARKETING]} />
      <PrivateRoute path={`${match.url}linktree/detail/:id`} component={LinktreeDetail} hasAnyAuthorities={[AUTHORITIES.MARKETING]} />

      <ErrorBoundaryRoute path={`${match.url}role/menumanagement`} component={MenuManagement} />
      <ErrorBoundaryRoute path={`${match.url}role/rolemanagement`} component={RoleManagement} />
      <ErrorBoundaryRoute path={`${match.url}role/authoritymanagement`} component={AuthorityManagement} />
      <ErrorBoundaryRoute path={`${match.url}role/rolemenu`} component={RoleMenu} />
      <ErrorBoundaryRoute path={`${match.url}notification`} component={Notification} />
      <ErrorBoundaryRoute path={`${match.url}karyawan/add`} component={KaryawanAddManual} />
      <ErrorBoundaryRoute path={`${match.url}karyawan/upload`} component={KaryawanAdd} />
      <ErrorBoundaryRoute path={`${match.url}karyawan`} component={Karyawan} />
      <ErrorBoundaryRoute path={`${match.url}group/detail/:id`} component={GroupDetail} />
      <ErrorBoundaryRoute path={`${match.url}group`} component={Group} />
      <ErrorBoundaryRoute path={`${match.url}/new/404`} component={NewNotFound} />
      <ErrorBoundaryRoute path={`${match.url}/new/401`} component={NewUnAuthorize} />
      <ErrorBoundaryRoute path={`${match.url}levelgroup`} component={LevelOrganization} />
      <ErrorBoundaryRoute path={`${match.url}listofapprovers`} component={ListofApprovers} />
      <ErrorBoundaryRoute path={`${match.url}usermanual`} component={UserManual} />
      {/* investment manager one asia */}
      <PrivateRoute path={`${match.url}investmentmanager`} exact component={InvestmentManager} hasAnyAuthorities={[AUTHORITIES.ONE_ASIA]}/>
      <PrivateRoute path={`${match.url}investmentmanager/detail`} exact component={InvestmentManagerDetail} hasAnyAuthorities={[AUTHORITIES.ONE_ASIA]}/>
      <PrivateRoute path={`${match.url}investmentmanager/createnewtc`} exact component={CreateNewTC} hasAnyAuthorities={[AUTHORITIES.ONE_ASIA]}/>
      <PrivateRoute path={`${match.url}investmentmanager/createnewtc/preview`} exact component={CreateNewTCPreview} hasAnyAuthorities={[AUTHORITIES.ONE_ASIA]}/>
      <PrivateRoute path={`${match.url}investmentmanager/createnewtc/result`} exact component={CreateNewTCResult} hasAnyAuthorities={[AUTHORITIES.ONE_ASIA]}/>
      <PrivateRoute path={`${match.url}investmentmanager/historygeneratetc`} exact component={HistoryGenerateTC} hasAnyAuthorities={[AUTHORITIES.ONE_ASIA]}/>
      <PrivateRoute path={`${match.url}investmentmanager/historygeneratetc/result`} exact component={HistoryGenerateTCResult} hasAnyAuthorities={[AUTHORITIES.ONE_ASIA]}/>
      {/* career */}
      <PrivateRoute path={`${match.url}career/candidates`} exact component={CandidateList} hasAnyAuthorities={[AUTHORITIES.CAREER]}/>
      <PrivateRoute path={`${match.url}career/candidate/detail/:id/:id2`} exact component={CandidateDetail} hasAnyAuthorities={[AUTHORITIES.CAREER]}/>
      <PrivateRoute path={`${match.url}career/jobs`} exact component={ListJobs} hasAnyAuthorities={[AUTHORITIES.CAREER]}/>
      <PrivateRoute path={`${match.url}career/jobs/detail/:id`} exact component={JobsDetail} hasAnyAuthorities={[AUTHORITIES.CAREER]}/>
      <PrivateRoute path={`${match.url}career/jobs/add`} exact component={JobsAdd} hasAnyAuthorities={[AUTHORITIES.CAREER]}/>
      <PrivateRoute path={`${match.url}career/jobs/edit/:id`} exact component={JobsEdit} hasAnyAuthorities={[AUTHORITIES.CAREER]}/>
      {/* Whistleblowing */}
      <PrivateRoute path={`${match.url}whistleblow/report`} exact component={WhistleblowingList} hasAnyAuthorities={[AUTHORITIES.WHISTLEBLOWING]}/>
      <PrivateRoute path={`${match.url}whistleblow/report-detail/:id`} exact component={WhistleblowingDetail} hasAnyAuthorities={[AUTHORITIES.WHISTLEBLOWING]}/>
      {/* anti money laundry */}
      <PrivateRoute path={`${match.url}aml`} exact component={AntiMoneyLaundry} hasAnyAuthorities={[AUTHORITIES.AML]} />
      {/* research report */}
      {/*<PrivateRoute path={`${match.url}summary-analyst`} exact component={SummaryAnalyst} hasAnyAuthorities={[AUTHORITIES.RESEARCH_REPORT]} />
      <PrivateRoute path={`${match.url}rating-and-target`} exact component={RatingAndTarget} hasAnyAuthorities={[AUTHORITIES.RESEARCH_REPORT]} />
      <PrivateRoute path={`${match.url}profile-analyst`} exact component={ProfileAnalyst} hasAnyAuthorities={[AUTHORITIES.RESEARCH_REPORT]} />
      <PrivateRoute path={`${match.url}make-new-request`} exact component={MakeNewRequest} hasAnyAuthorities={[AUTHORITIES.RESEARCH_REPORT]} />
      <PrivateRoute path={`${match.url}make-new-request/:id`} exact component={MakeNewRequest} hasAnyAuthorities={[AUTHORITIES.RESEARCH_REPORT]} />*/}
    </Switch>
  </div>
);

export default Routes;
